.section {
  padding: 20px 0;

  .title {
    font-size: 24px;
    margin-bottom: 16px;
  }

  .loader {
    width: 100%;
    height: auto;
    aspect-ratio: 297 / 89;

    @media screen and (max-width: 600px) {
      aspect-ratio: 193 / 143;
    }
  }

  //.categories {
  //  display: flex;
  //  flex-direction: row;
  //  flex-wrap: nowrap;
  //  overflow-x: hidden;
  //  gap: 36px;
  //
  //  .category {
  //    display: flex;
  //    flex-direction: column;
  //    justify-content: center;
  //    align-items: center;
  //  }
  //
  //  @media screen and (max-width: 768px) {
  //    gap: 24px;
  //  }
  //
  //  @media screen and (max-width: 500px) {
  //    gap: 16px;
  //  }
  //}
}

.section {
  padding: 20px 0;

  .brands {
    display: flex;
    flex-wrap: nowrap;
    gap: 16px;
    overflow-x: hidden;
    padding-bottom: 18px;

    .brand {
      min-width: 120px;
      aspect-ratio: 4 / 3;
    }
  }
}

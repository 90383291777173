.section {
  padding: 20px 0 50px;

  .title {
    font-size: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;
  }

  .sales {
    display: flex;
    flex-direction: column;

    .salesGrid {
      display: flex;
      flex-wrap: nowrap;
      gap: 32px;
      overflow-x: hidden;

      .sale {
        width: 100%;
        min-width: 451px;
        height: auto;
        aspect-ratio: 250 / 141;
      }
    }
  }
}

@import "/src/styles/mixin";

.titleWrap {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    padding: 60px 0;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 40px 0;
  }
}

.main {
  position: relative;
  padding-bottom: 0;
}

.title {
  @include font24;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;

  &Link {
    @include font14;
    position: relative;
    display: flex;
    gap: 16px;
    align-items: center;
    font-weight: 500;
    color: var(--grey-color);

    &::before {
      content: "";
      width: 0;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &::after {
      content: "";
      width: 32px;
      height: 2px;
      background-color: var(--grey-color);
      transition: width 0.2s ease;
    }

    &:hover {
      &::before {
        width: 16px;
      }

      &::after {
        width: 16px;
      }
    }
  }
}

.nestedBanner {
  height: auto;
}

.skeleton {
  margin: 50px 0 24px;
  height: 172px;
  width: 100%;
  z-index: 2;
  padding: 40px 0 20px;

  @media screen and (max-width: 767px) {
    height: 152px;
  }
}

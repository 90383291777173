.skeleton {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: 14px;
  background-color: #e5e5e5;
  overflow: hidden;
  animation-name: highlightAnimate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  .skeleton_highlight {
    display: none;
    position: absolute;
    top: -50%;
    left: 0;
    height: 300%;
    width: 30%;
    max-width: 100px;
    filter: blur(1.6rem);
    background-color: var(--white-color);
    z-index: 2;
  }

  @keyframes highlightAnimate {
    0% {
      background-color: #f3f3f3;
    }

    50% {
      background-color: #e5e5e5;
    }

    100% {
      background-color: #f3f3f3;
    }
  }
}

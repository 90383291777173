@import "src/styles/mixin";

.product {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 12px;
  transition: 0.25s ease;

  &_mark {
    //position: absolute;
    //top: 4px;
    //left: 4px;
    border-radius: 50%;
    background: var(--primary-color);
    color: var(--white-color);
    width: 16px;
    height: 16px;
    outline: solid 3px var(--primary-color);
    outline-offset: 3px;
    display: flex;
    justify-content: center;
    align-items: center;

    &_wrap {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &_button {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;

      .tooltip {
        width: 14px;
        height: 14px;
        display: flex;
        justify-content: center;
        align-content: center;
      }

      .icon {
        width: 8px;
        height: 14px;
        opacity: 0;
        transition: 0.25s ease;
      }

      &:hover {
        .icon {
          opacity: 1;
        }
      }
    }
  }

  &_info {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(14px);
    padding: 8px 4px;
    z-index: 2;
    border: 1px solid var(--white-color);
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(79, 79, 79, 0.3);
    cursor: default;
    transform: translateY(-7px);

    &_button {
      position: absolute;
      top: 2px;
      left: 5px;
      transition: 0.25s ease-in;
      z-index: 3;
    }

    .open {
      left: 0;
      transform: rotate(90deg);
    }

    .closed {
      left: 5px;
      transform: rotate(-90deg);
    }

    .content {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      padding: 8px;
      width: 220px;
      height: 310px;
      transition: all 0.25s ease-out;

      &_visible {
        opacity: 1;
      }

      &_hidden {
        opacity: 0;
        pointer-events: none;
      }

      .add {
        display: flex;
        flex-direction: column;

        .add_product {
          display: flex;
          flex-direction: column;
          gap: 12px;

          .button {
            padding: 10px 24px;
            @include contentButton;
          }
        }
      }

      &_product {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .name {
          white-space: wrap;
          -webkit-line-clamp: 3;
          line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 72px;
        }
      }
    }
  }

  .visible {
    width: 220px;
    height: 310px;
    transition: all 0.25s ease-out;
    backdrop-filter: blur(8px);
    border-radius: var(--border-radius-small);
  }

  .hidden {
    width: 32px;
    height: 32px;
    transition: all 0.25s ease-out;
    border-radius: 30px;
  }
}

.video {
  &Wrap {
    position: relative;
    width: 100%;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80px;
      height: 80px;
      border-radius: 100%;
      background-image: url("/images/pictures/play-video.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }
}
.img {
  width: 100%;
  height: 100%;
  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    order: 2;
  }
}
.iframe {
  width: 100%;
  height: 531px;
  @media only screen and (min-width: 1024px) and (max-width: 1559.98px) {
    height: 463px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1023.98px) {
    height: 373px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    height: 50vh;
  }
}

.section {
  margin-bottom: 32px;

  .cards {
    display: flex;
    gap: 16px;
    overflow-x: auto;

    .card {
      min-width: 220px;
      width: 220px;

      .stars {
        display: flex;
        flex-direction: row;
        gap: 4px;
        margin-bottom: 10px;
      }

      @media (max-width: 768px) {
        min-width: 180px;
        width: 180px;
      }
    }
  }
}

.section {
  margin-bottom: 32px;

  .title {
    font-size: 24px;
    margin-bottom: 16px;
    display: flex;
    justify-content: space-between;

    .titleLink {
      font-size: 16px;
      color: #999;
    }
  }

  .blogs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 32px;
    overflow-x: auto;

    .blog {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 458px;

      .image {
        aspect-ratio: 57 / 32;
      }

      @media screen and (max-width: 1024px) {
        min-width: 400px;
      }

      @media screen and (max-width: 700px) {
        min-width: calc(100svw - 40px);
      }
    }

    @media screen and (max-width: 768px) {
      gap: 16px;
    }
  }
}

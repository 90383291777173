.slide {
  height: 100%;

  .blog_card {
    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      width: 100%;
      max-width: 458px;
    }

    @media screen and (max-width: 425px) {
      max-width: calc(100svw - 20px);
    }
  }
}

.img {
  height: auto;
}
.wrap {
  margin-bottom: 32px;
}
.link {
  display: flex;
}
.swiper {
  padding-bottom: 30px;
}

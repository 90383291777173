.section {
  margin-bottom: 32px;

  .seasons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 36px;
    overflow-x: auto;

    .season {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 456px;
      aspect-ratio: 228 / 301;

      @media screen and (max-width: 768px) {
        min-width: 280px;
      }
    }

    @media screen and (max-width: 768px) {
      gap: 16px;
    }
  }
}

@import "/src/styles/mixin";

.video {
  width: 100%;
  margin-bottom: 24px;

  &Wrap {
    max-width: 100%;

    @media only screen and (min-width: 1366px) and (max-width: 1559.98px) {
      max-width: 831px;
    }

    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      max-width: 100%;
    }

    @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
      display: flex;
      flex-direction: column;
    }
  }
}

.date {
  @include font14;
  margin-bottom: 12px;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    order: 1;
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 16px;
  }
}

.title {
  @include font24;
  margin-bottom: 12px;
}

.description {
  @include font14;

  @media only screen and (min-width: 320px) and (max-width: 1023.98px) {
    order: 3;
  }
}

.blog {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  margin-bottom: 24px;
  @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
    flex-direction: column;
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 8px;
    gap: 24px;
  }
}

.aside {
  display: flex;
  width: 100%;
  max-width: 350px;

  @media screen and (max-width: 1365px) {
    max-width: 100%;
  }

  &Title {
    font-size: 18px;
    display: none;
    margin-bottom: 12px;
    font-weight: 700;

    @media only screen and (min-width: 320px) and (max-width: 1365.98px) {
      display: block;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-bottom: 0;
  }
}

.loader {
  height: 616px;
  margin: 20px 0 0;

  @media screen and (max-width: 767px) {
    height: 549px;
  }

  @media screen and (max-width: 440px) {
    height: auto;
    aspect-ratio: 19 / 25;
  }
}
